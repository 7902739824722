import {TranslationLanguageEnum} from '@v2/core/enums/translation-language.enum';
import {IMasterData} from '@v2/core/models/masterdata';

export interface PatientNameTranslation {
  id?: string;
  patientId?: string;
  firstName?: string
  lastName?: string
  thaiFirstName?: string
  thaiLastName?: string
  isEnglishPrimaryLang?: boolean
  // using below fields for full name
  middleName?: string
  thaiMiddleName?: string
  initial?: IMasterData | string;

  // added below field to avoid production build error
  fullName_FirstLast?: string;
}

export interface PatientNameTranslationV2 {
  id?: string;
  patientId?: string;
  fullName_FirstLast?: string
  thaiFullName_FirstLast?: string
  isEnglishPrimaryLang?: boolean
  initial?: IMasterData | string;
  firstName?: string;
  lastName?: string;
}

export function translatePatientName(data: PatientNameTranslation, activeLanguage?: string): string {
  if (!data) {
    return '-';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiFirstName && data.thaiLastName) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getName(initial, data.thaiFirstName, data.thaiLastName);
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.firstName && data.lastName) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getName(initial, data.firstName, data.lastName);
  }

  if (data.isEnglishPrimaryLang) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getName(initial, data.firstName, data.lastName);
  }

  if (data.thaiFirstName && data.thaiLastName) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getName(initial, data.thaiFirstName, data.thaiLastName);
  }

  if (data.firstName && data.lastName) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getName(initial, data.firstName, data.lastName);
  }

  return '-';
}

export function translatePatientNameV2(data: PatientNameTranslationV2, activeLanguage?: string, prependInitials = true): string {
  if (!data) {
    return '-';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiFullName_FirstLast && data.thaiFullName_FirstLast.trim()) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getNameV2(initial, data.thaiFullName_FirstLast, prependInitials);
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.fullName_FirstLast && data.fullName_FirstLast.trim()) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getNameV2(initial, data.fullName_FirstLast, prependInitials);
  }

  if (data.isEnglishPrimaryLang) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getNameV2(initial, data.fullName_FirstLast, prependInitials);
  }

  if (data.thaiFullName_FirstLast && data.thaiFullName_FirstLast.trim()) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getNameV2(initial, data.thaiFullName_FirstLast, prependInitials);
  }

  if (data.fullName_FirstLast && data.fullName_FirstLast.trim()) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getNameV2(initial, data.fullName_FirstLast, prependInitials);
  }

  return '-';
}

export function translatePatientFullNameExcludeMiddle(data: PatientNameTranslation): string {
  if (data.isEnglishPrimaryLang) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getFullName(initial, data.firstName, null, data.lastName);
  }

  if (data.thaiFirstName && data.thaiLastName) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getFullName(initial, data.thaiFirstName, null, data.thaiLastName);
  }

  if (data.firstName && data.lastName) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getFullName(initial, data.firstName, null, data.lastName);
  }

  return '-'
}

export function translatePatientFullName(data: PatientNameTranslation, activeLanguage?: string): string {

  if (!data) {
    return '-';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiFirstName && data.thaiLastName) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getFullName(initial, data.thaiFirstName, data.thaiMiddleName, data.thaiLastName);
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.firstName && data.lastName) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getFullName(initial, data.firstName, data.middleName, data.lastName);
  }

  if (data.isEnglishPrimaryLang) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getFullName(initial, data.firstName, data.middleName, data.lastName);
  }

  if (data.thaiFirstName && data.thaiLastName) {
    const initial = getInitial(data, TranslationLanguageEnum.THAI);
    return getFullName(initial, data.thaiFirstName, data.thaiMiddleName, data.thaiLastName);
  }

  if (data.firstName && data.lastName) {
    const initial = getInitial(data, TranslationLanguageEnum.ENGLISH);
    return getFullName(initial, data.firstName, data.middleName, data.lastName);
  }

  return '-';
}

export function translatePatientFirstName(data: PatientNameTranslation, activeLanguage?: string): string {

  if (!data) {
    return '';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiFirstName) {
    return data.thaiFirstName;
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.firstName) {
    return data.firstName;
  }

  if (data.isEnglishPrimaryLang) {
    return data.firstName;
  }
  if (data.thaiFirstName) {
    return data.thaiFirstName;
  }
  if (data.firstName) {
    return data.firstName;
  }
  return '';
}

export function translatePatientMiddleName(data: PatientNameTranslation, activeLanguage?: string): string {

  if (!data) {
    return '';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiMiddleName) {
    return data.thaiMiddleName;
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.middleName) {
    return data.middleName;
  }

  if (data.isEnglishPrimaryLang) {
    return data.middleName;
  }
  if (data.thaiMiddleName) {
    return data.thaiMiddleName;
  }
  if (data.middleName) {
    return data.middleName;
  }
  return '-';
}

export function translatePatientLastName(data: PatientNameTranslation, activeLanguage?: string): string {

  if (!data) {
    return '';
  }

  if (activeLanguage === TranslationLanguageEnum.THAI && data.thaiLastName) {
    return data.thaiLastName;
  }

  if (activeLanguage === TranslationLanguageEnum.ENGLISH && data.lastName) {
    return data.lastName;
  }

  if (data.isEnglishPrimaryLang) {
    return data.lastName;
  }
  if (data.thaiLastName) {
    return data.thaiLastName;
  }
  if (data.lastName) {
    return data.lastName;
  }
  return '';
}

function getName(initial: string, firstName: string, lastName: string): string {
  return initial + getValue(firstName) + getValue(lastName, false);
}

function getNameV2(initial: string, name: string, prependInitials = true): string {
  const value = getValue(name, false);
  return prependInitials ? initial + value : value;
}

function getFullName(initial: string, firstName: string, middleName: string, lastName: string): string {
  return initial + getValue(firstName) + getValue(middleName) + getValue(lastName, false);
}

function getInitial(data: PatientNameTranslation | PatientNameTranslationV2, lang: string = TranslationLanguageEnum.ENGLISH): string {
  const initial = data.initial;

  if (!initial) {
    return '';
  }

  if (typeof initial === 'string') {
    return getValue(initial);
  }

  if (lang === TranslationLanguageEnum.ENGLISH && initial.label) {
    return getValue(initial.label);
  }

  if (lang === TranslationLanguageEnum.THAI && initial.thaiLabel) {
    return getValue(initial.thaiLabel);
  }

  if (data.isEnglishPrimaryLang) {
    return getValue(initial.label);
  }

  if (initial.thaiLabel) {
    return getValue(initial.thaiLabel);
  }

  if (initial.label) {
    return getValue(initial.label);
  }

  return '-';
}

export function generatePatientObjFromFullName(fullName: string): PatientNameTranslation {
  if (!fullName) {
    return {} as PatientNameTranslation;
  }

  const name = fullName.split(' ');
  return {
    firstName: name[0],
    lastName: name.length > 1 ? name[1] : null,
    id: null
  };
}

export function generatePatientObj(firstName: string, lastName: string): PatientNameTranslation {
  return {
    firstName,
    lastName,
    id: null
  };
}

function getValue(value: string, appendSpace = true) {
  if (!value) {
    return '';
  }
  return appendSpace ? value + ' ' : value;
}

export type patientNameDisplay =
  'fullName'
  | 'patientFullName'
  | 'fullNameWithInitials'
  | 'firstLastWithInitials'
  | 'firstLastName'
  | 'firstName'
  | 'imageInitials'
  | 'middleName'
  | 'lastName';
