export const MAX_DATE =  new Date('Dec 31, 2035');
// UAT
export const HOSPITAL_LOGO = window.location.origin + '/assets/images/hospitals/pat-rangsit-hospital.png';
export const PRINT_HEADER_BG = window.location.origin + '/assets/images/nutrition-meal-header-print-background.svg';
export const HOSPITAL_ADDRESS = '733/347, Moo 8, Phaholyotin Road, Khukot, Lam Luk Ka, Pathumthani, 12130, Thailand +66 2 998 9888';
export const HOSPITAL_NAME = 'PatRangsit Mother & Child Hospital';
export const HOSPITAL_CONTACT = '+66 2 998 9888';
export const COUNTRY_NAME = 'Thailand'

export const COMPANY_NAME_THAI = 'บริษัท ปทุมรักษ์เวชการ จำกัด (สำนักงานใหญ่)';
export const COMPANY_NAME_ENG = 'PATUMRUKS VEJCHAKARN COMPANY LIMITED';
export const COMPANY_CONTACT = '+0-2998-9888';
export const COMPANY_ADDRESS_LINE1 = '733/347, พหลโยธิน';
export const COMPANY_CITY = 'คูคต';
export const COMPANY_STATE = 'ลำลูกกา';
export const COMPANY_COUNTRY = 'ปทุมธานี';
export const COMPANY_POSTAL_CODE = 12130;
export const COMPANY_ADDRESS_THAI = '733 / 347 หมู่ 8 ถนนพหลโยธิน ตำบลคูคต อำเภอลำลูกกา จังหวัดปทุมธานี 12130 โทร 0 2998 9888, แฟกซ์ 0 2998 9000';
export const COMPANY_ADDRESS_ENG = '733 / 347 Moo 8 Phaholyothin Road, Khukhot, Lamlukka, Pathumthani 12130. Tel. 0 2998 9888, Fax 0 2998 9000';
export const COMPANY_SUB_ADDRESS_THAI = 'ทะเบียนการค้า/เลขประจำตัวผู้เสียภาษี 0135540003399';
export const RECEIPT_FOOTER_THAI = 'ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์ต่อเมื่อมีลายเซ็นของผู้รับเงินและหากเป็นการชำระด้วยเช็ค/บัตรเครดิต ใบเสร็จนี้จะสมบูรณ์ต่อเมื่อขึ้นเงินแล้ว'
export const RECEIPT_FOOTER_ENG = 'No receipt will be recognized unless signed by Collector & if by cheque / Credit Card, this receipt is not valid unless it is cleared'
export const TAX_INVOICE_RECEIPT_ENG = 'Tax Invoice/Receipt';
export const TAX_INVOICE_RECEIPT_THAI = 'ใบกำกับภาษี/ใบเสร็จรับเงิน';
export const FORCEFULLY_FINALIZE_BILL_ORDER_NOTE = 'Order Canceled By Cashier As Bill Finalize';
export const TAX_ID = '013554000399';

export const PO_RECEIPT_TERMS_AND_CONDITIONS = '1.บริษัทต้องการต้นฉบับใบกำกับภาษีพร้อมสำเนาใบกำกับภาษี 4 ฉบับ\n' +
  '2.แนบเอกสารใบสั่งซื้อทุกครั้ง พร้อมส่งสินค้าและวางบิลเรียกเก็บเงิน\n' +
  '3.รับสินค้าตั้งแต่วันที่ 1 - 28 ของทุกเดือน ในเวลาทำการ วันจันทร์ - วันศุกร์ เวลา 08.30 น. - 16.30 น.)\n' +
  '4.ส่งมอบสินค้า แผนกตรวจรับ อาคาร 4 ชั้น 5';

// UAT 1
// export const HOSPITAL_LOGO = window.location.origin + '/assets/images/hospitals/rajapruek-hospital.png';
// export const HOSPITAL_ADDRESS = 'Mittraphap Rd, Tambon Nai Mueang, Mueang Khon Kaen District, Khon Kaen 40000, Thailand +6643333555';
// export const HOSPITAL_NAME = 'Ratchaphruek Hospital';
// export const HOSPITAL_CONTACT = '+66 4 333 3555';
