import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {TRANSLOCO_LANG, TranslocoService} from '@ngneat/transloco';
import {getDOBBasedOnPref, GREGORIAN_DATE_TO_BUDDHIST_DATE_DIFFERENCE, isNullOrUndefined} from '@v2/core/functions/functions';
import {IPatient} from '@v2/core/models/masterdata';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';
import {AgeDisplayFormatEnum} from '../../shared-v2/components/age-display/age-display-format.enum';

@Pipe({
  name: 'getAgeFromDOBPref',
  pure: false
})
export class GetAgeFromDOBPrefPipe implements PipeTransform, OnDestroy {

  displayFormat = AgeDisplayFormatEnum.FULL;

  private lastValue = '';
  private lastKey: string | Date | Moment | undefined;
  private listenToLangChange: boolean;
  private subscription: Subscription | null = null;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string | null,
    private cdr: ChangeDetectorRef
  ) {
    this.listenToLangChange = this.shouldListenToLangChanges(this.translocoService, this.providerLang);
  }

  transform(dateInfo: Partial<IPatient>, ageOnDate: string | Date | Moment = null, displayFormat: AgeDisplayFormatEnum = AgeDisplayFormatEnum.FULL): string {
    let date: string | Date | Moment = getDOBBasedOnPref(dateInfo);
    if (!date) {
      return '';
    }
    let now = dateInfo.isBuddhistDOB ? moment().add(GREGORIAN_DATE_TO_BUDDHIST_DATE_DIFFERENCE, 'years') : moment();
    if (ageOnDate) {
      now = dateInfo.isBuddhistDOB ? moment(ageOnDate).add(GREGORIAN_DATE_TO_BUDDHIST_DATE_DIFFERENCE, 'years') : moment(ageOnDate);
    }
    date = moment(date, 'DD-MM-YYYY');

    let years = now.diff(date, 'year');
    date.add(years, 'years');

    let months = now.diff(date, 'months');
    date.add(months, 'months');

    let days = now.diff(date, 'days');
    years = isNaN(years) ? 0 : years;
    months = isNaN(months) ? 0 : months;
    days = isNaN(days) ? 0 : days;

    if (date === this.lastKey) {
      return this.lastValue;
    }

    this.lastKey = date;
    this.displayFormat = displayFormat;
    this.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(
      this.listenOrNotOperator(this.listenToLangChange)
    ).subscribe((activeLang: string) => {
      this.updateValue(years, months, days, activeLang, this.displayFormat);
    });

    return this.lastValue;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private translateKey(key: string, lang: string): string {
    return this.translocoService.translate<string>(`common.${key}`, {}, lang);
  }

  private updateValue(years: number, months: number, days: number, activeLang: string, displayFormat: number) {
    this.lastValue = this.resolveValue(years, months, days, activeLang, displayFormat);
    this.cdr.markForCheck();
  }

  private resolveValue(years: number, months: number, days: number, activeLanguage: string, displayFormat: number): string {
    if (!activeLanguage || (isNullOrUndefined(years) && isNullOrUndefined(months) && isNullOrUndefined(days))) {
      return null;
    }
    return this.getAge(years, months, days, activeLanguage, displayFormat);
  }

  private getAge(years: number, months: number, days: number, activeLanguage: string, displayFormat: number) {
    if (displayFormat === AgeDisplayFormatEnum.NONE) {
      return `${years} ${months} ${days}`;
    } else if (displayFormat === AgeDisplayFormatEnum.SHORT) {
      return this.getShortFormatValue(years, months, days);
    } else if (displayFormat === AgeDisplayFormatEnum.FULL) {
      const yearLabel = this.translateKey(`year${this.isPlural(years)}`, activeLanguage);
      const monthLabel = this.translateKey(`month${this.isPlural(months)}`, activeLanguage);
      const dayLabel = this.translateKey(`day${this.isPlural(days)}`, activeLanguage);
      let result = '';
      result += `${years} ${yearLabel}`;
      result += ` ${months} ${monthLabel}`;
      result += ` ${days} ${dayLabel}`;
      return result;
    }
  }

  private listenOrNotOperator(listenToLangChange: boolean) {
    return listenToLangChange ? source => source : take(1);
  }

  private getShortFormatValue(years: number, months: number, days: number): string {
    let result = '';
    result += `${years} (Y)`;
    result += ` ${months} (M)`;
    result += ` ${days} (D)`;
    return result;
  }

  private isPlural(value: number): string {
    return value > 1 ? 's' : ''
  }

  private shouldListenToLangChanges(service: TranslocoService, lang: string) {
    const [hasStatic] = this.getPipeValue(lang, 'static');
    if (hasStatic === false) {
      // If we didn't get 'lang|static' check if it's set in the global level
      return service.config.reRenderOnLangChange;
    }
    // We have 'lang|static' so don't listen to lang changes
    return false;
  }

  private getPipeValue(str: string, value: string, char = '|'): [boolean, string] {
    if (typeof str === 'string') {
      const splitted = str.split(char);
      const lastItem = splitted.pop();
      return lastItem === value ? [true, splitted.toString()] : [false, lastItem];
    }

    return [false, ''];
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
