import {HttpParams} from '@angular/common/http';
import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {DoctorRequestStatusEnum} from '@v2/core/enums/appointment-status.enum';
import {ElementPriceEnum} from '@v2/core/enums/element-price.enum';
import {OrderOriginEnum} from '@v2/core/enums/order-origin.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {TableBadgeEnum} from '@v2/core/enums/table-badge.enum';
import {TranslationLanguageEnum} from '@v2/core/enums/translation-language.enum';
import {WorkingDayEnum} from '@v2/core/enums/working-day.enum';
import {
  IAddress,
  ICatalogElement,
  IDepartment,
  IDepartmentMinimal,
  IDoctor,
  IElementPrice,
  IErrorHandlerConfig,
  IIncomeType,
  IMasterData,
  IPageableData,
  IPatient
} from '@v2/core/models/masterdata';
import {IClaimItem} from '@v2/core/models/masterdata/IBilling.master-data';
import {IConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {ICustomFilters, IPatientFilter} from '@v2/core/models/masterdata/IFilters.master-data';
import {IPackageDiscountFactor} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IPatientManagementRequestStatus} from '@v2/core/models/masterdata/IPatientManagementRequest';
import * as _ from 'lodash';
import * as moment from 'moment';
import {DurationInputArg1, unitOfTime} from 'moment';
import momentTimeZone from 'moment-timezone';
import {APP_SETTINGS} from '../../../core/services/setting';
import {IFilterFormDataModel} from '../../shared-v2/components/filter/filter-form.model';
import {DoctorFeeFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/doctor-fee-form.model';
import {MedicationFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/medication-form.model';
import {NutritionMealFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/nutrition-meal-form.model';
import {PathologyFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/pathology-form.model';
import {ProcedureFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/procedure-form.model';
import {RadiologyFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/radiology-form.model';
import {SupplyFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/supply-form.model';
import {IPatientOrderRouteInfo} from '../../shared-v2/components/patient-order/resources/models/IPatientOrderRouteInfo';
import {AddBedTypeEnum, BedTransferProcessStepEnum, TransferBedTypeEnum} from '../enums/bed-transfer-request-type.enum';

export function getApplicationVersion() {
  return APP_SETTINGS.app_version || 'v1.0.0';
}

export function setFilter(filterOptions: ICustomFilters) {
  let params = new HttpParams();
  if (Object.keys(filterOptions).length) {
    Object.keys(filterOptions).forEach(keys => {
      if (keys) {
        const key = (keys === 'filters') ? JSON.stringify(filterOptions[keys]) : filterOptions[keys].toString();
        params = params.append(keys, key);
      }
    });
  }
  return params;
}

export function getElementPrices(prices: IElementPrice[]): Partial<ICatalogElement> {
  let opdPrice: IElementPrice;
  let ipdPrice: IElementPrice;
  let baseSellingPrice: IElementPrice;
  let masterCatalogPrice: IElementPrice;
  if (prices) {
    opdPrice = prices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.opd);
    ipdPrice = prices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.ipd);
    baseSellingPrice = prices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.baseSelling);
    masterCatalogPrice = prices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.masterCatalog);
  }
  return {
    opdPrice: opdPrice ? opdPrice.price : 0,
    ipdPrice: ipdPrice ? ipdPrice.price : 0,
    baseSellingPrice: baseSellingPrice ? baseSellingPrice.price : 0,
    masterCatalogCost: masterCatalogPrice ? masterCatalogPrice.price : 0
  };
}

export function isIPDOrder(patientOrderRouteInfo: IPatientOrderRouteInfo) {
  return (patientOrderRouteInfo.visitType && patientOrderRouteInfo.visitType === OrderOriginEnum.IPD) || (patientOrderRouteInfo.orderOrigin === OrderOriginEnum.drIpd || patientOrderRouteInfo.orderOrigin === OrderOriginEnum.nsIpd);
}

export function checkForCommercialOrderCreation(patientOrderRouteInfo: IPatientOrderRouteInfo): boolean {
  /* do not create commercial orders in case of IPD because for IPD, it will be created from IPD -> treatment plan -> medication -> place order */
  /* for Lab, Radio and OR -> medication orders, create commercial order */
  const preventCommercialOrderCreation = (
    patientOrderRouteInfo.visitType &&
    patientOrderRouteInfo.visitType === OrderOriginEnum.IPD &&
    patientOrderRouteInfo.orderOrigin !== OrderOriginEnum.or &&
    patientOrderRouteInfo.orderOrigin !== OrderOriginEnum.lab &&
    patientOrderRouteInfo.orderOrigin !== OrderOriginEnum.radiology
  ) || (
    patientOrderRouteInfo.orderOrigin === OrderOriginEnum.drIpd ||
    patientOrderRouteInfo.orderOrigin === OrderOriginEnum.nsIpd
  );
  return !preventCommercialOrderCreation;
}

export function isEmptyObject(obj: ICustomFilters): boolean {
  return Object.values(obj).every(x => isNullOrUndefined(x));
}

export function alphanumericXref(): string {
  return Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
}

export function numericXref(): number {
  return Math.ceil(Math.random() * (100000000 - 1000) + 1000);
}

export function medicalCertificateXref(): string {
  return `MED${new Date().getTime()}`;
}

export function AIMIntegrationSetupXref(): string {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
}

export function getTimeStamp(date?: string | Date): number {
  return date ? moment(date).unix() : moment().unix();
}

export function todayDate(): string {
  return moment().startOf('day').format();
}

export function todayDateWithEndTime(): string {
  return moment().endOf('day').format();
}

export function currentMoment(): string {
  return moment().format();
}

export function currentTimeZone(): string {
  return momentTimeZone.tz.guess();
}

export function currentTimeZoneOffset(): string {
  return momentTimeZone().tz(currentTimeZone()).format('Z')
}

export function getCurrentMomentFromDate(date: string) {
  const dateWithCurrentTime = moment(date).add({hours: moment().hour(), minutes: moment().minute(), seconds: moment().second()}).format()
  return dateWithCurrentTime;
}

export function momentDateTime(date: string): string {
  return moment(date).format();
}

export function getTimeFromDate(date: string, format = ''): string {
  return date && moment(date).format(format || 'hh:mm a');
}

export function get24HrFormatTimeFromDate(date: string): string {
  return date && moment(date).format('HH:mm A');
}

export function getFormattedDate(date: string): string {
  return moment(date).format();
}

export function formatDateTime(date: string | Date, format?: string): string {
  return !!format ? moment(date).format(format) : moment(date).format();
}

export function getTimeDurationFromNow(date: string): string {
  return moment(date).fromNow();
}

/**
 * Making @params date optional in getStartOfDay(date) and getEndOfDay(date)
 * If @params date is given then these 2 function will return startOf and endOf for parameter date
 * If @params date is not given it will return startOf and endOf for current date
 * And by this we don't need function todayDate() and todayDateWithEndTime()
 */

export function getStartOfDay(date?: string | Date): string {
  return date ? moment(date).startOf('day').format() : moment().startOf('day').format();
}

export function getStartOfMonth(date?: string | Date): string {
  return date ? moment(date).startOf('month').format() : moment().startOf('month').format();
}

export function getStartOfPreviousMonth(): string {
  return moment().subtract(1, 'months').startOf('month').format();
}

export function getEndOfDay(date?: string | Date): string {
  return date ? moment(date).endOf('day').format() : moment().endOf('day').format();
}

export function getEndOfMonth(date?: string | Date): string {
  return date ? moment(date).endOf('month').format() : moment().endOf('month').format();
}

export function getEndOfPreviousMonth(): string {
  return moment().subtract(1, 'months').endOf('month').format();
}

export function getStartOfPreviousYears(count?: number): string {
  return count ? moment().subtract(count, 'year').startOf('year').format() : moment().subtract(1, 'year').startOf('year').format();
}

export function getUTCDate(date: string) {
  const selectedDate = !!date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  const dayStartTime = formatDateTime(getStartOfDay(), 'HH:mm:ss');
  return selectedDate + 'T' + dayStartTime + 'Z';
}

export function isBeforeToday(date: string): boolean {
  return moment(date).isBefore(moment());
}

export function isTodayDate(date: string): boolean {
  return moment(date).isSame(moment(), 'day');
}

export function isAfterDate(date1: string, date2: string, granularity: unitOfTime.StartOf = 'day'): boolean {
  return moment(date1).isAfter(moment(date2), granularity);
}

export function isSameOrAfter(date1: string, date2: string): boolean {
  return moment(date1).isSameOrAfter(moment(date2));
}

export function isBeforeDate(date1: string, date2: string, granularity: unitOfTime.StartOf = 'day'): boolean {
  return moment(date1).isBefore(moment(date2), granularity);
}

export function isSameDate(date1: string, date2: string): boolean {
  return moment(date1).isSame(date2, 'day');
}

export function addUnitInDate(date: string, amount: DurationInputArg1, unit: unitOfTime.DurationConstructor, format: string = ''): string {
  if (!unit) {
    return date;
  }
  return moment(date).add(amount, unit).format(format);
}

export function getName(firstName: string, lastName: string, prefix = ''): string {
  return `${prefix}${firstName ? firstName : ''}${lastName ? (' ' + lastName) : ''}`;
}

export function getCurrentTime(format = 'hh:mm A') {
  return moment().format(format);
}

export function getStartTime(format = 'hh:mm A') {
  return moment().startOf('day').format(format);
}

export function getEndTime(format = 'hh:mm A') {
  return moment().endOf('day').format(format);
}

export function getEndOfTime(time: string, timeFormat = 'HH:mm', returnFormat = 'HH:mm:ss') {
  return moment(time, timeFormat).endOf('minute').format(returnFormat);
}

export function getCurrentDateTime(format?: string): string {
  return format ? moment().format(format) : moment().format();
}

export function concatDateTime(date, time, timeFormat = 'h:mm A', setEndValueForSeconds = false) {
  const preferredDate = date && moment(date).format('YYYY-MM-DD');
  let preferredTime;
  if (setEndValueForSeconds) {
    preferredTime = time && moment(time, [timeFormat]).set('seconds', 59).format('HH:mm:ss');
  } else {
    preferredTime = time && moment(time, [timeFormat]).format('HH:mm:ss')
  }
  return preferredDate && preferredTime ? moment(preferredDate + 'T' + preferredTime).format() : null;
}

export function toFormData(obj: object, form = null, namespace = null) {
  const formData: FormData = form || new FormData();
  let formKey: string;

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        toFormData(obj[property], formData, property);
      } else {
        // if it's a string or a File object
        formData.append(formKey, obj[property]);
      }
    }
  }

  return formData;
}

export function getDefaultErrorHandlerConfig(scope: string, type: 'UI' | 'Snackbar' = 'UI', showImage = true): IErrorHandlerConfig {
  return {scope, type, additionalConfig: {showImage}};
}

export function objectValidator(control: AbstractControl) {
  if (control.value && !(typeof control.value === 'object') && (typeof control.value === 'string')) {
    return {isObject: true};
  }
  return null;
}

export function dataURIToBLOB(dataURI: any, type: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type});
}

export function arrayLengthValidator(): ValidatorFn {
  return (formControl: FormControl): { [key: string]: any } => {
    if (!!formControl.value && Array.isArray(formControl.value) && formControl.value.length > 0) {
      return null;
    }
    return {noData: true};
  };
}

export function getStaffName(doctorData: IDoctor): string {
  if (doctorData) {
    return `${doctorData.firstName} ${doctorData.lastName || ''}`;
  }
  return '-';
}

export function getTransferBedType(type: string): string {
  switch (type) {
    case TransferBedTypeEnum.TRANSFER_TO_HOLD_BED:
      return TransferBedTypeEnum.TRANSFER_TO_HOLD_BED_UI;
    case TransferBedTypeEnum.TRANSFER_TO_NEW_BED:
      return TransferBedTypeEnum.TRANSFER_TO_NEW_BED_UI;
    default:
      return type;
  }
}

export function getAddBedType(type: string) {
  switch (type) {
    case AddBedTypeEnum.ADD_BED_AND_TRANSFER:
      return AddBedTypeEnum.ADD_BED_AND_TRANSFER_UI;
    case AddBedTypeEnum.ADD_HOLD_BED:
      return AddBedTypeEnum.ADD_HOLD_BED_UI;
    default:
      return type;
  }
}

export function getBedTransferProcessStepUILabel(step: string, isTransferRequest = false) {
  switch (step) {
    case BedTransferProcessStepEnum.BED_READY:
      return BedTransferProcessStepEnum.BED_READY_UI;
    case BedTransferProcessStepEnum.CLEANING_COMPLETED:
      return BedTransferProcessStepEnum.CLEANING_COMPLETED_UI;
    case BedTransferProcessStepEnum.PHYSICAL_TRANSFER:
      return isTransferRequest ? BedTransferProcessStepEnum.PHYSICAL_TRANSFER_UI_TRANSFER : BedTransferProcessStepEnum.PHYSICAL_TRANSFER_UI_ADD_BED;
    default:
      return step;
  }
}

export function getAddress(addressData: IAddress): string {
  return concatString(', ', addressData.line1, addressData.line2, addressData.line3, addressData.city, addressData.state, addressData.country, addressData.zipCode);
}

export function groupArrayElementsUsingKey<T>(array: Array<T>, key: keyof T) {
  return _.groupBy(array, key);
}

export function groupBy<T>(array: T[], callbackFn: (item: T) => Array<string | number | boolean>) {
  const groups = {};
  // tslint:disable-next-line:only-arrow-functions
  array.forEach((o, i) => {
    const group = JSON.stringify(callbackFn(o));
    groups[group] = groups[group] || [];
    groups[group].push({...o, index: i});
  });
  // tslint:disable-next-line:only-arrow-functions
  return Object.keys(groups).map((group) => {
    return groups[group];
  })
}

export function partitionArray<T>(array: Array<T>, conditionFn: (object: T) => boolean): Array<Array<T>> {
  return _.partition(array, conditionFn);
}

export function convertTimeTo24Hr(timeIn12Hr: string) {
  const [time, modifier] = timeIn12Hr.split(' ');
  let hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
}

export function getDayNumFromDate(day: string) {
  return Object.keys(WorkingDayEnum).find(key => WorkingDayEnum[key] === day);
}

export function getDatesBetweenStartEndDate(startDate: string, endDate: string): string[] {
  const dates: string[] = [];
  while (startDate <= endDate) {
    dates.push(startDate);
    startDate = moment(startDate).add({day: 1}).format();
  }
  return dates;
}

export function convertTimeTo12Hr(timeIn24Hr: string) {
  // const [time, modifier] = timeIn24Hr.split(' ');
  let hours = Number(timeIn24Hr.split(':')[0])
  const minutes = timeIn24Hr.split(':')[1]
  const modifier = hours >= 12 ? 'PM' : 'AM'

  if (hours > 12) {
    hours = hours - 12
  } else if (hours === 0) {
    hours = 12
  }

  return `${hours}:${minutes} ${modifier}`;
}

export function timeDifference(unit, startDateTime?: string, endDateTime?: string): number {
  const start = startDateTime ? startDateTime : getStartOfDay();
  const end = endDateTime ? endDateTime : getEndOfDay();
  return Math.abs(moment(end).diff(start, unit));
}

export function getDifferenceBetweenTwoDates(date1: string, date2: string, differenceInType: unitOfTime.Diff, getAbsoluteValue = true) {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);
  const difference = momentDate1.diff(momentDate2, differenceInType);
  return getAbsoluteValue ? Math.abs(difference) : difference;
}

export function generateDiscount(from: number, to: number, interval: number): number[] {
  const numberArray: number[] = [];
  let percentage = from;
  do {
    numberArray.push(percentage);
    percentage += interval;
  } while (percentage <= to);
  return numberArray;
}

export const RFC_3339_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export function getUTCDateTime(date?: string) {
  return date ? moment(date).utc().format(RFC_3339_DATE_FORMAT) : moment().utc().format(RFC_3339_DATE_FORMAT);
}

export function getUTCDateTimeRFC3339(date?: string) {
  return date ? moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z' : moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
}

export function getUpdatedAt<T extends IConcurrencyRequest>(model: T) {
  return model.updatedAt ? getUTCDateTime(model.updatedAt) : undefined;
}

export function concatString(concatWith: string, ...args: any[]): string {
  return args.filter(Boolean).join(concatWith);
}

export function jsonToQueryString(json, encode = false) {
  const queryParams = encode ? Object.keys(json).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])) : Object.keys(json).map((key) => `${key}=${key === 'filters' ? JSON.stringify(json[key]) : json[key].toString()}`);
  return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
}

export function uniqueArray<T>(value: Array<T>): Array<T> {
  return _.uniq(value)
}

export function getMasterDataKey(value: IMasterData): number {
  return value ? value.key : null;
}

export function getMasterDataLabel(value: IMasterData, defaultValue: string = ''): string {
  return value ? value.label : defaultValue;
}

export function isArray(value: Array<unknown>): boolean {
  return value && Array.isArray(value);
}

// function to divide array into chunks of array
export function chunkArray(myArray, chunk_size) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
}

export function sortCollection<T>(collection: T[], sortBy: string, orderType: 'asc' | 'desc' = 'asc'): T[] {
  if (orderType === 'asc') {
    return collection.sort((o1, o2) => (o1[sortBy] > o2[sortBy]) ? 1 : ((o2[sortBy] > o1[sortBy]) ? -1 : 0));
  } else {
    return collection.sort((o1, o2) => (o2[sortBy] > o1[sortBy]) ? 1 : ((o1[sortBy] > o2[sortBy]) ? -1 : 0));
  }
}

export function validateLanguage(word, lang) {
  // regex for english translation
  const regexEn = '[A-Z]|[a-z]';

  // regex for thai translation (\u0E00-\u0E7F)
  const regexTh = '[\u0E00-\u0E7F]';

  const pattern = (lang === TranslationLanguageEnum.ENGLISH) ? regexEn : regexTh;
  const re = new RegExp(`^(${pattern}|[0-9]|[\‘\;\:\°\฿\"\'\’\“\”\,\`\?\!\@\#\$\%\^\&\*\)\{\}\(\+\=\.\_\\-]|[\/]|[\\]|[ ]|[\n]|[\.])+$`, 'g');
  return re.test(word);
}

export function LanguageBasedCollectionSortAscending<T>(collection: T[], sortBy: string): T[] {
  return collection.sort((o1, o2) => {
    if (validateLanguage(o1[sortBy], TranslationLanguageEnum.ENGLISH) && validateLanguage(o2[sortBy], TranslationLanguageEnum.ENGLISH)) {
      return (o1[sortBy] > o2[sortBy]) ? 1 : ((o2[sortBy] > o1[sortBy]) ? -1 : 0)
    } else if (validateLanguage(o1[sortBy], TranslationLanguageEnum.THAI) && validateLanguage(o2[sortBy], TranslationLanguageEnum.THAI)) {
      return o1[sortBy].localeCompare(o2[sortBy]) ? 1 : ((o2[sortBy].localeCompare(o1[sortBy])) ? -1 : 0);
    } else {
      return 1;
    }
  });
}

export function LanguageBasedCollectionSortDescending<T>(collection: T[], sortBy: string): T[] {
  return collection.sort((o1, o2) => {
    if (validateLanguage(o1[sortBy], TranslationLanguageEnum.ENGLISH) && validateLanguage(o2[sortBy], TranslationLanguageEnum.ENGLISH)) {
      return (o2[sortBy] > o1[sortBy]) ? 1 : ((o1[sortBy] > o2[sortBy]) ? -1 : 0)
    } else if (validateLanguage(o1[sortBy], TranslationLanguageEnum.THAI) && validateLanguage(o2[sortBy], TranslationLanguageEnum.THAI)) {
      return o2[sortBy].localeCompare(o1[sortBy]) ? 1 : ((o1[sortBy].localeCompare(o2[sortBy])) ? -1 : 0);
    } else {
      return 1;
    }
  });
}

export function LanguageBasedCollectionSort<T>(collection: T[], sortBy: string, orderType: 'asc' | 'desc' = 'asc'): T[] {
  if (orderType === 'asc') {
    return LanguageBasedCollectionSortAscending(collection, sortBy);
  } else {
    return LanguageBasedCollectionSortDescending(collection, sortBy);
  }
}

export function mapDepartmentDataToDepartmentMinimal(department: IDepartment): IDepartmentMinimal {
  return department ? {departmentId: department.id, departmentName: department.name} : null;
}

export function mapFullNameInDoctor(doctor: IDoctor): IDoctor {
  return doctor ? {...doctor, fullName: getName(doctor.firstName, doctor.lastName)} : null;
}

export function getEmptyPaginationResponse<T>(): IPageableData<T> {
  return {
    items: [],
    currentItemCount: 0,
    pageIndex: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 15
  };
}

// NOTE: use this function only when patient filters are specified in following sequence: 1) first name 2) last name 3) patient id
export function mapPatientFilters(formData: IFilterFormDataModel, returnNullIfEmpty = true, phoneNumberKey: 'text1' | 'text2' | 'text3' | 'text4' | 'text5' | 'text6' = null): Partial<IPatientFilter> {
  const patientFilter: Partial<IPatientFilter> = {};
  patientFilter.firstName = !!formData.text1 ? formData.text1 : undefined;
  patientFilter.lastName = !!formData.text2 ? formData.text2 : undefined;
  patientFilter.patientXref = !!formData.text3 ? formData.text3 : undefined;
  if (!!phoneNumberKey) {
    patientFilter.phoneNumber = !!formData[phoneNumberKey] ? encodeURIComponent(formData[phoneNumberKey]) : undefined;
  }

  if (!returnNullIfEmpty) {
    return patientFilter;
  }
  const filterValueExists = Object.values(patientFilter).filter(value => !!value).length > 0;
  return filterValueExists ? patientFilter : null;
}

export function isNullOrUndefined(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export function getGenericOriginName(orderOrigin: OrderOriginEnum): OrderOriginEnum {
  if (orderOrigin === OrderOriginEnum.drIpd || orderOrigin === OrderOriginEnum.nsIpd) {
    return OrderOriginEnum.IPD
  } else if (orderOrigin === OrderOriginEnum.nsOpd || orderOrigin === OrderOriginEnum.drOpd) {
    return OrderOriginEnum.OPD;
  } else {
    return orderOrigin;
  }
}

export function isOrderFromSameOrigin(originOfOA: string, originToCompare: string): boolean {
  if (!originOfOA || !originToCompare) {
    return false;
  }
  return originOfOA.toLowerCase() === originToCompare.toLowerCase();
}

export function getOrderIdForOrderType(orderType: string, data: RadiologyFormDataModel | NutritionMealFormDataModel | PathologyFormDataModel | SupplyFormDataModel | ProcedureFormDataModel | MedicationFormDataModel | DoctorFeeFormDataModel) {
  switch (orderType) {
    case OrderTypeEnum.radiology:
      return (data as RadiologyFormDataModel).id;
    case OrderTypeEnum.lab:
      return (data as PathologyFormDataModel).id;
    case OrderTypeEnum.nutritionMeal:
      return (data as NutritionMealFormDataModel).id;
    case OrderTypeEnum.Procedure:
    case OrderTypeEnum.TreatmentPlanProcedure:
    case OrderTypeEnum.TreatmentProcedure:
      return (data as ProcedureFormDataModel).id;
    case OrderTypeEnum.Supply:
      return (data as SupplyFormDataModel).poSupplyId;
    case OrderTypeEnum.DoctorFee:
      return (data as DoctorFeeFormDataModel).id;
    case OrderTypeEnum.Medication:
    case OrderTypeEnum.TreatmentPlan:
      return (data as MedicationFormDataModel).id;
    default:
      return null;
  }
}

export function getUserDisplayedOrderType(orderType: IMasterData) {
  if (!orderType) {
    return null;
  }
  if (orderType.label === OrderTypeEnum.TreatmentPlan) {
    return {...orderType, label: OrderTypeEnum.Supply as string};
  } else if (orderType.label === OrderTypeEnum.TreatmentProcedure) {
    return {...orderType, label: OrderTypeEnum.Procedure as string};
  }
  return orderType;
}

export function disableControls(controls: FormControl[]) {
  controls.forEach((control: FormControl) => {
    control.disable();
  });
}

export function enableControls(controls: FormControl[]) {
  controls.forEach((control: FormControl) => {
    control.enable();
  });
}

export function getIncomeTypeIdForDiscountFactor(data: IPackageDiscountFactor): string {
  let incomeTypeId = null;
  if (data.incomeCategoryId) {
    incomeTypeId = data.incomeCategoryId;
  } else if (data.incomeSubCategoryId) {
    incomeTypeId = data.incomeSubCategoryId;
  } else if (data.incomeTypeId) {
    incomeTypeId = data.incomeTypeId;
  } else if (data.incomeSectionId) {
    incomeTypeId = data.incomeSectionId;
  }
  return incomeTypeId;
}

export function getIncomeTypeInfoForClaimItem(claimItem: IClaimItem): [string, string, IIncomeType] {
  let name = null;
  let incomeTypeId = null;
  let incomeType = null;
  if (claimItem.categoryId) {
    incomeTypeId = claimItem.categoryId;
    name = claimItem.category.index + ' ' + claimItem.category.name;
    incomeType = claimItem.category;
  } else if (claimItem.subCategoryId) {
    incomeTypeId = claimItem.subCategoryId;
    name = claimItem.subCategory.index + ' ' + claimItem.subCategory.name;
    incomeType = claimItem.subCategory;
  } else if (claimItem.incomeTypeId) {
    incomeTypeId = claimItem.incomeTypeId;
    name = claimItem.incomeType.computed;
    incomeType = claimItem.incomeType;
  } else if (claimItem.sectionId) {
    incomeTypeId = claimItem.sectionId;
    name = claimItem.section.index + ' ' + claimItem.section.name;
    incomeType = claimItem.section;
  }
  return [incomeTypeId, name, incomeType];
}

export function getDOBBasedOnPref(dobInfo: Partial<IPatient>, dateFormat: string = 'DD-MM-YYYY'): string {
  if (!dobInfo || (dobInfo && !dobInfo.dob && !dobInfo.buddhistDOB)) {
    return '';
  }
  /* return date based on used preference */
  if (dobInfo.isBuddhistDOB) {
    /* if preference is of buddhist dob, then check if buddhist dob value exists and return it, otherwise return dob */
    return formatDateTime(dobInfo.buddhistDOB || dobInfo.dob, dateFormat);
  } else {
    /* if preference is of gregorian dob, then check if gregorian dob value exists and return it, otherwise return buddhist dob */
    return formatDateTime(dobInfo.dob || dobInfo.buddhistDOB, dateFormat);
  }
}

export function getModalWidth(reducerValue: number): [string, string, string] {
  if (!reducerValue) {
    return [`${MODAL_DEFAULT_SIZE}px`, `${MODAL_MIN_WIDTH}px`, `${MODAL_MAX_WIDTH}px`];
  }
  const modalWidth = window.innerWidth - reducerValue;
  return [`${modalWidth}px`, `${MODAL_MIN_WIDTH}px`, `${MODAL_MAX_WIDTH}px`];
}

/* Do Not Use this validator when you have nested form structure */
export function hasValue(fieldNames?: string[]): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    let isValid = false;
    let fieldsToCheck: string[] = [];
    if (!fieldNames || (!!fieldNames && fieldNames.length === 0)) {
      fieldsToCheck = Object.keys(group.controls);
    } else {
      fieldsToCheck = fieldNames;
    }
    for (const key of fieldsToCheck) {
      const value = (group.get(key) as FormControl).value as string;
      if (!!value && !!value.toString().trim()) {
        isValid = true;
        return null;
      }
    }
    return {noData: true};
  };
}

export function refineExternalRouteURL(url: string): string {
  if (!url) {
    return;
  }
  const httpPrefix = 'http://';
  const httpsPrefix = 'https://';
  if (!(url.startsWith(httpPrefix) || url.startsWith(httpsPrefix))) {
    url = httpPrefix + url;
  }
  return url;
}

export function removeProtocolFromURL(url: string): string {
  if (!url) {
    return;
  }
  const httpPrefix = 'http://';
  const httpsPrefix = 'https://';
  if (url.startsWith(httpPrefix)) {
    return url.substr(httpPrefix.length);
  }
  if (url.startsWith(httpsPrefix)) {
    return url.substr(httpsPrefix.length);
  }
  return url;
}

/* CONSTANTS */

export const GREGORIAN_DATE_TO_BUDDHIST_DATE_DIFFERENCE = 543;
export const ITEM_NAME_MAX_LENGTH = 150;
export const ITEM_THAI_NAME_MAX_LENGTH = 200;
export const PHYSICAL_EXAMINATION_DESCRIPTION_MAX_LENGTH = 1000;
export const PHYSICAL_EXAMINATION_NOTE_MAX_LENGTH = 1000;
export const REQUEST_TIMEOUT = 300000; // 5 minutes
export const HTTP_REQUEST_TIMEOUT = 120000; // 2 minutes
export const CHECK_NEW_VERSION_INTERVAL = 1000 * 60 * 60 * 8; // 8 hours
export const APPLY_PRICE_PARAMETER = true
export const ADMISSION_REQUEST_EXIST_ERROR_IN_VERIFY_PATIENT = 'Patient is already admitted';
export const ADMISSION_REQUEST_IN_PROGRESS_ERROR = 'Patient admission is already in-progress';
export const ADMISSION_REQUEST_EXIST_ERROR = 'Admit request already present for this date';
export const MEDICAL_PACKAGE_ALREADY_UTILISED_ERROR = 'Selected medical package is already utilized.';
export const MEDICAL_PACKAGE_VISIT_ID_REQUIRED_ERROR = 'Package Visit is required';
export const ADMISSION_ALREADY_LINKED_ERROR = 'This admission is already linked with this OPD appointment';
export const ADMISSION_ALREADY_UNLINKED_ERROR = 'This admission is not linked with this OPD appointment';
export const VISIT_ALREADY_LINKED_ERROR = 'This opd appointment is already linked. Cannot linked opd appointment with more than 1 ipd admission';
export const MAX_DATE = new Date('Dec 31, 2035');
export const MAX_UPLOAD_SIZE = '10MB';
export const MODAL_DEFAULT_SIZE = 998;
export const MODAL_MIN_WIDTH = 980;
export const MODAL_MAX_WIDTH = 1224;
export const MODAL_XS_WIDTH = 540;
export const DOCTOR_PREFIX = 'Dr. ';
export const NOT_APPLICABLE = 'N/A';
export const ADMIN_ROLE = 'admin';

export function getAppointmentOrigin(aptOrigin: IMasterData, isFollowUp: boolean, neededToReviewedByDoctor: boolean, isPrimaryDoctor: boolean, isNS = false): TableBadgeEnum {
  if (neededToReviewedByDoctor) {
    return TableBadgeEnum.PHARMACY;
  } else {
    if (isFollowUp) {
      return TableBadgeEnum.FOLLOWUP;
    }
    if (!aptOrigin) {
      return null;
    }
    if (aptOrigin.label === TableBadgeEnum.REFERRAL) {
      return TableBadgeEnum.CONSULTATION;
    }
    if (!isNS && !isPrimaryDoctor) {
      return TableBadgeEnum.JOINT_ADMISSION;
    }
    return aptOrigin.label as TableBadgeEnum;
  }
}

export function getIPDAppointmentOrigin(isFollowUp: boolean, isDischarged: boolean, neededToReviewedByDoctor: boolean, jointAdmissionStatus: IPatientManagementRequestStatus) {
  const doctorRequestStatus = jointAdmissionStatus && jointAdmissionStatus.doctorRequestStatus ? jointAdmissionStatus.doctorRequestStatus.label : '';
  if (neededToReviewedByDoctor && doctorRequestStatus !== DoctorRequestStatusEnum.REQUESTED) {
    return TableBadgeEnum.PHARMACY;
  } else {

    if (isDischarged) {
      return TableBadgeEnum.DISCHARGE;
    }

    if (isFollowUp) {
      return TableBadgeEnum.FOLLOWUP;
    }
    if (!jointAdmissionStatus) {
      return null;
    }
    const status = jointAdmissionStatus.attachedEvent && jointAdmissionStatus.attachedEvent.eventType ? jointAdmissionStatus.attachedEvent.eventType.label : '';
    if (!status) {
      return null;
    }
    if (status === TableBadgeEnum.CONSULTATION) {
      return TableBadgeEnum.CONSULTATION;
    }
    if (status === TableBadgeEnum.PATIENT_TRANSFER) {
      return TableBadgeEnum.TRANSFERRED;
    }
    return status as TableBadgeEnum;
  }
}

/*export function handleBatchQtyValidity(
  mainFormGroup: FormGroup,
  requestedItemsArray: FormArray,
  dispatchedItemsArray: FormArray,
  locallyDispatchedBatchData: { [batchId: string]: { batchRemainingQty: number, itemIndexes: number[], dispatchedQty: number } },
  checkRequestedQtyValidation: boolean = false
) {
  const dispatchQtyControl = mainFormGroup.get('qty') as FormControl;
  const requestedItems = requestedItemsArray ? requestedItemsArray.value as IItemBatchDispatchQtyInfo[] : [];
  const dispatchedItems = dispatchedItemsArray.value as IItemBatchDispatchQtyInfo[] || [];
  const selectedBatch = mainFormGroup.get('batchNumber') && mainFormGroup.get('batchNumber').value as BatchNumberViewModel;
  const enteredQty = dispatchQtyControl ? +dispatchQtyControl.value : 0;
  const orderQty = mainFormGroup.get('originalRequestedQty') && +mainFormGroup.get('originalRequestedQty').value;
  const requestedItemId = mainFormGroup.get('itemId') && mainFormGroup.get('itemId').value as string;

  if (!dispatchedItems.length) {
    if (selectedBatch) {
      const selectedBatchQty = selectedBatch.remainingQty || 0;
      setQtyErrors(dispatchQtyControl, enteredQty, orderQty, selectedBatchQty, checkRequestedQtyValidation);
    }
    return locallyDispatchedBatchData;
  } else {
    let matchedDispatchedItems: { [indexInDispatchItems: number]: IItemBatchDispatchQtyInfo } = [];
    if (checkRequestedQtyValidation) {
      const matchedRequestedItemIndex = requestedItems.findIndex(item => item.itemId === requestedItemId);
      const matchedRequestedItem = requestedItems[matchedRequestedItemIndex];
      const matchedRequestedItemControl = requestedItemsArray.at(matchedRequestedItemIndex) as FormGroup;
      matchedDispatchedItems = dispatchedItems.reduce((result, next, index) => {
        if (next.itemId === matchedRequestedItem.itemId) {
          result[index] = next;
        }
        return result;
      }, {} as { [indexInDispatchItems: number]: IItemBatchDispatchQtyInfo });
      const dispatchedQtySum = Object.values(matchedDispatchedItems).reduce((sum, next) => sum + +next.qty, 0);
      locallyDispatchedBatchData = getLocallyDispatchedBatchItemsInfo(matchedDispatchedItems);
      if (dispatchedQtySum > matchedRequestedItem.originalRequestedQty) {
        setQtyError(dispatchQtyControl, 'qty');
      } else {
        setLocallyDispatchedItemsErrors(locallyDispatchedBatchData, dispatchedItemsArray);
      }
      const requestedQty = matchedRequestedItem.originalRequestedQty - dispatchedQtySum;
      matchedRequestedItemControl.patchValue({requestedQty});
      const enteredQtyForMatchedItem = +matchedRequestedItem.qty;
      if (enteredQtyForMatchedItem) {
        const selectedBatchForMatchedRequestedItem = locallyDispatchedBatchData[matchedRequestedItem.selectedBatchId];
        let batchQty = matchedRequestedItem.batchNumber && matchedRequestedItem.batchNumber.remainingQty;
        if (selectedBatchForMatchedRequestedItem) {
          batchQty = selectedBatchForMatchedRequestedItem.batchRemainingQty - selectedBatchForMatchedRequestedItem.dispatchedQty;
        }
        setQtyErrors(matchedRequestedItemControl.get('qty') as FormControl, enteredQtyForMatchedItem, requestedQty, batchQty, checkRequestedQtyValidation);
      }
      return locallyDispatchedBatchData;
    } else {
      matchedDispatchedItems = dispatchedItems.reduce((result, next, index) => {
        if (selectedBatch && next.itemId === requestedItemId && next.selectedBatchId === selectedBatch.batchId) {
          result[index] = next;
        }
        return result;
      }, {} as { [indexInDispatchItems: number]: IItemBatchDispatchQtyInfo });
      // dispatchedQtySum is used to verify whether the matched dispatched items qty should not increase batch qty
      const dispatchedQtySum = Object.values(matchedDispatchedItems).reduce((sum, next) => sum + +next.qty, 0);
      locallyDispatchedBatchData = getLocallyDispatchedBatchItemsInfo(matchedDispatchedItems);
      if (Object.keys(locallyDispatchedBatchData).length) {
        Object.values(locallyDispatchedBatchData).forEach(info => {
          if (dispatchedQtySum > info.batchRemainingQty) {
            info.itemIndexes.forEach(itemIndex => {
              setQtyError(dispatchedItemsArray.at(itemIndex).get('qty') as FormControl, 'batch');
            });
          } else {
            info.itemIndexes.forEach(itemIndex => {
              setQtyError(dispatchedItemsArray.at(itemIndex).get('qty') as FormControl, 'other');
            })
          }
        });
      } else {
        updateBatch(locallyDispatchedBatchData, dispatchedItemsArray);
      }
    }
    return locallyDispatchedBatchData;
  }
}

function updateBatch(locallyDispatchedBatchData: { [batchId: string]: { batchRemainingQty: number, itemIndexes: number[], dispatchedQty: number } }, dispatchedItemsArray: FormArray) {
  // This block will be executed when a batch is selected which does not match any other dispatched items batches
  const dispatchedData = locallyDispatchedBatchData || {};
  dispatchedItemsArray.controls.forEach((control, index) => {
    const currentBatch = control.get('batchNumber').value as BatchNumberViewModel;
    if (currentBatch) {
      if (dispatchedData[currentBatch.batchId]) {
        dispatchedData[currentBatch.batchId].dispatchedQty += +control.get('qty').value;
        dispatchedData[currentBatch.batchId].itemIndexes.push(index);
      } else {
        dispatchedData[currentBatch.batchId] = {
          dispatchedQty: +control.get('qty').value,
          itemIndexes: [index],
          batchRemainingQty: currentBatch && currentBatch.remainingQty ? currentBatch.remainingQty : 0
        }
      }
      if (currentBatch && currentBatch.remainingQty < dispatchedData[currentBatch.batchId].dispatchedQty) {
        setQtyError(control.get('qty') as FormControl, 'batch');
      } else {
        setQtyError(control.get('qty') as FormControl, 'other');
      }
    }
  });
}

function getLocallyDispatchedBatchItemsInfo(matchedDispatchedItems: { [indexInDispatchItems: number]: IItemBatchDispatchQtyInfo }) {
  return Object.entries(matchedDispatchedItems).reduce((result, [key, item]) => {
    if (!result[item.selectedBatchId]) {
      result[item.selectedBatchId] = {
        batchRemainingQty: (item.batchNumber && item.batchNumber.remainingQty) || 0,
        itemIndexes: [+key],
        dispatchedQty: +item.qty || 0
      };
    } else {
      result[item.selectedBatchId].itemIndexes.push(+key);
      result[item.selectedBatchId].dispatchedQty += +item.qty || 0;
    }
    return result;
  }, {} as { [batchId: string]: { batchRemainingQty: number, itemIndexes: number[], dispatchedQty: number } });
}

function setLocallyDispatchedItemsErrors(locallyDispatchedBatchData: { [batchId: string]: { batchRemainingQty: number, itemIndexes: number[], dispatchedQty: number } }, dispatchedItemsArray) {
  Object.values(locallyDispatchedBatchData).forEach(info => {
    if (info.dispatchedQty > info.batchRemainingQty) {
      info.itemIndexes.forEach(itemIndex => {
        setQtyError(dispatchedItemsArray.at(itemIndex).get('qty') as FormControl, 'batch');
      })
    } else {
      info.itemIndexes.forEach(itemIndex => {
        setQtyError(dispatchedItemsArray.at(itemIndex).get('qty') as FormControl, 'other');
      })
    }
  });
}

function setQtyErrors(control: FormControl, enteredQty: number, orderQty: number, batchQty: number, checkRequestedQtyValidation: boolean) {
  if (checkRequestedQtyValidation && (enteredQty > orderQty)) {
    setQtyError(control, 'qty');
  } else if (!isNullOrUndefined(batchQty) && (enteredQty > batchQty)) {
    setQtyError(control, 'batch');
  } else {
    setQtyError(control, 'other');
  }
}

function setQtyError(control: FormControl, errorType: 'qty' | 'batch' | 'other') {
  if (errorType === 'qty') {
    control.setErrors({requestedQtyExhausted: true});
  } else if (errorType === 'batch') {
    control.setErrors({batchQtyExhausted: true});
  } else {
    if (control.errors) {
      const {requestedQtyExhausted, batchQtyExhausted, ...otherErrors} = control.errors;
      control.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
    }
  }
  control.markAsTouched();
}*/

export function getDefaultValueIdsForFilter(defaultValue: string | string[], callbackFn: (val: string) => string | number): string | string[] | number | number[] {
  if (Array.isArray(defaultValue) && defaultValue.length) {
    const multiSelectDefaultValueIds = [];
    defaultValue.forEach(value => {
      const matchedValue = callbackFn(value);
      if (matchedValue) {
        multiSelectDefaultValueIds.push(matchedValue);
      }
    });
    return multiSelectDefaultValueIds;
  } else {
    return callbackFn(defaultValue as string);
  }
}

export function formatNumber(value: number, decimal = 2): number {
  return !isNullOrUndefined(value) ? Number(value.toFixed(decimal)) : null;
}
